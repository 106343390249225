import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './char-guide.scss';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { Alert, Col, Row } from 'react-bootstrap';

const NikkeGuidesLud: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Ludmilla: Winter Owner guide & review</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_xlud.jpg"
            alt="Wishlist"
          />
        </div>
        <div className="page-details">
          <h1>Ludmilla: Winter Owner guide & review</h1>
          <h2>
            A guide & review for Ludmilla: Winter Owner in NIKKE: Goddess of
            Victory.
          </h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Banner" />
        <StaticImage
          src="../../../images/nikke/generic/banner_lud.jpg"
          alt="Tia"
        />
        <p>
          <strong>Ludmilla: Winter Owner</strong> banner will be available:{' '}
          <strong>
            From the end of the December 7, 2023 maintenance to 4:59:59,
            December 28, 2023 (UTC+9)
          </strong>
        </p>
        <SectionHeader title="Foreword" />
        <div className="employee-container for-nikke">
          <NikkeCharacter
            mode="icon"
            slug="ludmilla-winter-owner"
            enablePopover
          />
        </div>
        <p>
          Calm, mature, and the Queen of Snow. Mommy Ludmilla invites us
          Servants to have a memorable, relaxing (and very lewd!) vacation in
          her hot springs! In full Christmas spirit, Ludmilla: Winter Owner
          gives us the present of a very simple-to-understand kit, all of which
          contributes to her amazing damage output, rivalling even Modernia when
          fighting Solo Bosses. Her skills allow her to do extra damage on top
          of what she is already shooting, and her burst is a selfish reload
          speed and ATK buff. She also has a self ammo reload, which when
          combined with the reload speed, means that she has everything an MG
          nikke needs to output tons of damage!
        </p>
        <SectionHeader title="Kit Analysis" />
        <p>
          Ludmilla: Winter Owner (abbreviated afterward as XLud) is a balanced
          unit with significant supportive capability and a high damage output,
          rivalling Modernia, against enemies with cores in particular. She not
          only provides Damage Taken, a seriously powerful debuff that
          permanently improves the team overall damage, but she also strengthens
          herself with Ammo Refund and Reload Speed, things MG wielders adore
          the most! Moreover, her kit is simple. You only need to land your
          shots, and that's it. She has an average basic attack multiplier that
          is also compounded with the additional damage provided by Skill 1 and
          Skill 2. However, you still want to minmax a little by ensuring she
          only reloads within 20s of her using Burst Skill for the acceleration.
        </p>
        <p>
          Like all MGs, one downside is that these weapons are heavily
          influenced by FPS. If the game runs at 60 FPS, you will be able to
          achieve maximum potential with her. However, so long as it is under 60
          FPS, your damage will be slightly reduced. One way to test this
          without any monitoring app is by listening to the sound cue of Skill 1
          triggering or watching the damage numbers. This should trigger every 1
          second if all your shots land.
        </p>
        <h6>Skill 1</h6>
        <blockquote>
          <p>■ Activates when landing 60 normal attacks. Affects the target.</p>
          <p>Damage Taken ▲ 12.56% for 3 sec.</p>
          <p>Deal 158.43% of final ATK as additional damage.</p>
          <p>■ Activates when landing 60 normal attacks. Affects self.</p>
          <p>Reloads 20 rounds of ammunition.</p>
        </blockquote>
        <p>
          One of XLud's best gimmick is Ammo Refund. Every time she lands 60
          shots, she recovers a certain number of bullets (dependent on skill
          level, from 11 to 20). This functions similarly to Bastion and is, in
          fact, slightly stronger than Bastion at max level. If she recovers 20
          bullets every 60 shots, then recovers bullets again from those 20, and
          again, it would amount to a 50% total increase. To compare, Bastion is
          around 42.8%. They are synergistic too. If you combine both, you get a
          whooping 172%! That said, we will discuss in detail later about which
          Cube is best. You might need to swap based on the team. In any case,
          the shots need to land to count.
        </p>
        <p>
          Her Skill 1 also constitutes a major part of her DPS, termed as
          Additional Damage, which cannot core damage but can CRIT. Every 60
          shots landed, she inflicts a certain amount of damage. It also applies
          Damage Taken debuff, which is one of the best debuffs in game. Just by
          having XLud in the team, you are basically multiplying the team's TDM
          by slightly over 12% (if no dilution happens from Novel, Blanc, or
          SHelm).
        </p>
        <h6>Skill 2</h6>
        <blockquote>
          <p>
            ■ Activates when hitting the Core for 60 times. Affects the target.
          </p>
          <p>Deal 109.64% of final ATK as additional damage.</p>
          <p>■ Activates at the beginning of Full Burst. Affects self.</p>
          <p>Critical Rate ▲ 14.6% for 3 sec.</p>
        </blockquote>
        <p>
          This skill consists of two components—damage every 60 core hits &
          critical rate buff every Full Burst. The damage is on the low side if
          we compare it to the combined Basic Attack and Skill 1 ratios and also
          demands hitting cores at that. However, it still plays an essential
          role in multiplying her DPS by a fixed amount. Meanwhile, the critical
          rate buff is definitely nice to have, despite seemingly being
          contradictory to her kit that revolves around hitting cores. Why is
          that? Remember that we said Additional Damage cannot core hit but can
          CRIT. Yes, while this buff only improves her Basic Attack damage a
          little due to dilution, it considerably boosts the damage from Skill 1
          and Skill 2.
        </p>
        <h6>Burst</h6>
        <blockquote>
          <p>■ Affects self.</p>
          <p>ATK ▲ 62.54% for 10 sec.</p>
          <p>Reloading Speed ▲ 67.2% for 20 sec.</p>
        </blockquote>
        <p>
          The most exciting part of her skill, Reload Speed! Wonderful! If you
          think this sounds too good, be informed that XLud's reload time is
          actually 3s, making her more reliant on Burst Skill level. That aside,
          her Burst Skill is relatively simple. She gets 10s of ATK buff and 20s
          of Reload Speed buff. The ATK buff is self-explanatory. Every level
          increases the value, which helps her deal more damage during her
          Burst. What about the Reload Speed? With Bastion, it just helps cut
          down time wasted. However, it gets very tricky with Resilience Cube,
          and the only way to understand it is through mathematics. For
          investment advice, please visit the Investment Section, where we
          attach graphs for Skill 1 and Burst Skill accordingly.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Usage" />
        <p>
          XLud displays great versatility. She can be used in all content except
          Arena. Below is the complete list of game modes with a short
          description for each:
        </p>
        <h6>Bossing</h6>
        <p>
          XLud has strong bossing potential. Once invested, she is independent
          and fits in many teams. However, due to her spectacular supportive
          capability and DPS, she will most likely join one of the top teams.
          She accumulates more damage when attacking bosses with exposed cores
          but still deals decent damage when there is no core. She is also the
          only serviceable Water DPS unit in game, making her much more valuable
          against Fire bosses due to elemental advantage.
        </p>
        <h6>Campaign</h6>
        <p>
          Strong ATK buff during Burst, raptures with cores, and Damage Taken.
          She can be used in campaign but may lack the AoE provided by other
          units such as Scarlet and Modernia. Besides that, she lacks any ATK
          buff outside her Burst, so that will incur a massive damage penalty.
        </p>
        <h6>Tower</h6>
        <p>
          Since Volume/Blanc/Noir is the meta composition for this Tower, using
          SAnis, who has an antagonistic kit, isn't really ideal. With this
          update, XLud will replace SAnis and wreak havoc on the raptures
          together with Alice.
        </p>
        <SectionHeader title="Investments" />
        <h5>Gear Investments</h5>
        <p>
          Like usual, OL every piece of her gear. Upgrade Head and Gloves to
          level 5. Can leave Chest at level 3 or max it to level 5 if you want
          to splurge a bit. As for the OL lines, you want a minimum of 2 Max
          Ammo. This will allow her to be independent, separated from Liter &
          Noir, and be paired with better supports, solely relying on
          self-provided Reload Speed buff to gain maximum uptime. For
          convenience and flexibility, you can get 3 Max Ammo lines instead. The
          rest should be ATK & Elemental DMG. CRIT Rate & CRIT DMG are good
          bonuses for buffing her additional damage.
        </p>
        <Alert variant="primary">
          <p>
            The reason why we are suggesting 2-3 Max Ammo lines is because XLud
            will have around 16-18s of Reload Speed buff downtime every 2
            rotations, during which she should NOT reload. For her to go through
            this window without reloading, you need a minimum of 2 Max Ammo
            lines and Skill 1 at level 10.
          </p>
        </Alert>
        <h5>Skill Investments</h5>
        <ul>
          <li>Skill 1: 10</li>
          <ul>
            <li>
              Higher levels deal more additional damage, refund significantly
              more ammo (great scaling; crucial to her kit), and enhances the
              Damage Taken debuff, contributing to higher team damage overall.
              Prioritize upgrading to level 10.
            </li>
          </ul>
        </ul>
        <StaticImage
          src="../../../images/nikke/generic/xlud_info.png"
          alt="Guide"
        />
        <ul>
          <li>Skill 2: 4-7</li>
          <ul>
            <li>
              No actual breakpoints. Investments improve damage. Scaling appears
              relatively weak. Make sure to allocate resources sparingly and
              avoid overinvesting, so you can also invest in other units.
            </li>
          </ul>
          <li>Burst: 5-10 (Recommended 10)</li>
          <ul>
            <li>
              Burst Skill investment, especially with Resilience (no Privaty in
              team), gets very tricky when you want to save resources and not
              upgrade it to level 10. Ideally, you should, for the highest ATK
              buff and the shortest reload time, but not everyone has that
              freedom.
            </li>
            <li>
              Firstly, let's understand that Reload Speed buff gets more
              apparent the closer it is to 100% (and eventually &gt;109% for
              removing the invisible Cover Time). At &gt;109%, your unit will
              have to waste ±11 frames every reload, whereas at 100%, your unit
              will have to waste another ±11 frames (totalling up to ±22
              frames). This may vary between units, but the numbers don't stray
              too far. Any other percentage will be equal to Visible Reload Time
              + 22 frames.
            </li>
            <li>
              Now, let's discuss about MG wind-up mechanics. When not firing for
              a prolonged period, MG will gradually lose fire rate. While it
              does slowly wind down soon after you stop shooting, it is more
              apparent if the Visible Pause exceeds 0.6s (excluding the ±22
              frames). Before then, quickly firing again after reloading will
              ramp the weapon up again to maximum fire rate in no time. This
              means between 0.5-0.6s of Visible Reload Time, you have to be
              frame perfect. Anything below 0.5s of Visible Reload Time should
              be easily doable. Note that this only affects the wind-up
              mechanic, and faster Reload Speed still means less time wasted!
            </li>
            <li>Therefore, what is the conclusion? Here are a few pointers:</li>
            <ul>
              <li>
                At Burst Skill level 7, you reach an ideal amount of Reload
                Speed buff, where you don't have to be exactly excellent with
                timings to maintain fire rate.
              </li>
              <li>
                At Burst Skill level 6, you can easily maintain MG's fire rate
                after each reload by quickly refiring. It is similar to using
                Guillotine (Resilience) + SAnis. There is still a slight fire
                rate loss.
              </li>
              <li>
                At Burst Skill level 5, you can maintain fire rate but need to
                be frame perfect.
              </li>
            </ul>
          </ul>
        </ul>
        <p>Breakpoints:</p>
        <ul>
          <li>Level 1: 66.65% =&gt; 1 second reload</li>
          <li>Level 5: 80.09% =&gt; 0.5973s reload</li>
          <li>Level 6: 83.45% =&gt; 0.4965s reload</li>
          <li>Level 7: 86.81% =&gt; 0.3957s reload</li>
          <li>Level 8: 90.17% =&gt; 0.2949s reload</li>
          <li>Level 9: 93.53% =&gt; 0.1941s reload</li>
          <li>Level 10: 96.89% =&gt; 0.09s reload</li>
        </ul>
        <h5>Cube Investments</h5>
        <StaticImage
          src="../../../images/nikke/generic/cube_4_sm.png"
          alt="Guide"
        />
        <p>
          We recommend Bastion for starters. If you have uninvested Burst and
          have insufficient Max Ammo OL to back it up, Bastion will improve her
          uptime by up to 172% alone (S1 level 10, Bastion level 7+). You can
          also place her in Liter/Blanc/Noir team for effortless ammo retaining.
        </p>
        <p>
          Once her Burst is invested and you acquire 2 Max Ammo lines, feel free
          to move her out and slap Resilience. This will reduce or eliminate the
          downtime incurred by reloading, thus increasing the total damage
          output over long periods. The minimum Burst level for usability is at
          least 5, but we recommend 6 or higher. It will feel much smoother at
          level 10.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Team Compositions" />
        <p>
          Since XLud is a powerful unit, she is to be paired with other strong
          units. Here are some examples:
        </p>
        <h5>Team #1</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="blanc" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="ludmilla-winter-owner"
                enablePopover
              />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="noir" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Flex</h5>
            <div className="placeholder-box">Flex</div>
          </div>
        </div>
        <p>
          One of the most foolproof team despite not offering the highest DPS.
          Damage Taken from Skill 1 is diluted thanks to Blanc. The team also
          does not provide consistent ATK buffs (other than Noir's), which may
          be inadequate for XLud. Liter's only lasts 5s. That said, the
          Liter/Noir/XLud combo allows infinite ammo without much hassle,
          particularly with lower investments. This variant uses Bastion.
        </p>
        <h5>Team #2</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="volume" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="tia" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="naga" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
              <NikkeCharacter mode="icon" slug="maxwell" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="ludmilla-winter-owner"
                enablePopover
              />
            </div>
          </div>
        </div>
        <p>
          Based on current data, this team offers the highest XLud's DPS so far,
          with Maxwell taking the lead. However, it would be a waste of Tia &
          Naga because Maxwell's DPS isn't that good. Red Hood might be a better
          choice. XLud is also one of the several units in game that can take
          full advantage of Red Hood's initial 70%+ ATK buff during RH's Burst,
          while other units require the use of their own Burst Skill to even
          deal considerable damage. For this team to work, you should fulfill
          the minimum investments (2 Max Ammo & Lv 5+ Burst).
        </p>
        <h5>Team #3</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="dorothy" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="placeholder-box">Flex</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="ludmilla-winter-owner"
                enablePopover
              />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="maxwell" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="privaty" enablePopover />
            </div>
          </div>
        </div>
        <p>
          A reload-heavy team focused on infinite ammo without high levels of
          Burst Skill. By utilizing Privaty's Burst and her own Burst, you can
          reach infinite ammo easily with either Resilience or Bastion. XLud
          offers similar DPS here versus in the Liter counterpart (does not
          account for Part Damage). B2 can be Centi, Diesel, Sin, Quency, Novel,
          Marciana, etc. This variant can use either Bastion or Resilience.
          Manual play is encouraged.
        </p>
        <h5>Team #4</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="bay-goddess-mary"
                enablePopover
              />
              <NikkeCharacter mode="icon" slug="n102" enablePopover />
              <NikkeCharacter mode="icon" slug="miranda" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="placeholder-box">Flex</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="ludmilla-winter-owner"
                enablePopover
              />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="maxwell" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="placeholder-box">Flex</div>
          </div>
        </div>
        <p>
          If nothing else works, or if you don't have any other units, then try
          to make a team with Maxwell. SMary, N102, and Miranda are decent B1
          choices. XMica is also an option, but she is not out yet.
          Nevertheless, judging by the kit, we believe they would be a
          compatible pair.
        </p>
        <SectionHeader title="Should You Pull" />
        <p>
          As the proverb states, you must respect your mommy, so not pulling
          Ludmilla is a disgrace to society and disrespectful in the eyes of
          Nikke gods. Literally, you should get at least a copy of her. She has
          an amazing value, and you won't be disappointed. In fact, we recommend
          2 extra copies for 2LB (max bond) for those seeking higher stats while
          still being economical. Ms. Ludmilla will definitely appreciate your
          charity.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Pros & Cons" />
        <Row className="pros-cons">
          <Col sm="12" md="6">
            <div className="box pros">
              <h5>Pros</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>Very high single-target DPS, great for bosses,</li>
                  <li>
                    Reload buffs and self-ammo reload skills - the 2 best things
                    an MG weapon type in Nikke can dream of,
                  </li>
                  <li>
                    Inflicts Damage-taken debuff, one of the best debuffs, on an
                    enemy, which allows herself and her team to do more damage,
                  </li>
                  <li>Works well even at lower investments,</li>
                  <li>
                    Highly flexible unit that can fit in multiple team
                    compositions,
                  </li>
                  <li>Mommy and lewd - the perfect combo!</li>
                </ul>
              </div>
            </div>
          </Col>
          <Col sm="12" md="6">
            <div className="box cons">
              <h5>Cons</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    Offers no AoE damage, so using her in the campaign as the
                    main B3 can prove to be hard,
                  </li>
                  <li>Below average in PvP.</li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <SectionHeader title="Final ratings" />
        <div className="ratings-container">
          <div className="ratings-box s">
            <h6>S</h6>
            <p>Story (Early)</p>
          </div>
          <div className="ratings-box s">
            <h6>S</h6>
            <p>Story (Late)</p>
          </div>
          <div className="ratings-box sss">
            <h6>SSS</h6>
            <p>Boss (Solo)</p>
          </div>
          <div className="ratings-box ss">
            <h6>SS</h6>
            <p>Boss (Adds)</p>
          </div>
          <div className="ratings-box c">
            <h6>C</h6>
            <p>PVP</p>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesLud;

export const Head: React.FC = () => (
  <Seo
    title="Ludmilla: Winter Owner guide & review | NIKKE | Prydwen Institute"
    description="A guide & review for Ludmilla: Winter Owner in NIKKE: Goddess of Victory."
    game="nikke"
  />
);
